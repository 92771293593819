<template>
  <div>
    <notice-detail :id="infoId"/>
  </div>
</template>

<script>
import NoticeDetail from '@/components/NoticeDetail.vue'

export default {
  components: {
    NoticeDetail
  },
  data() {
    return {
      infoId: ''
    }
  },
  created() {
    this.infoId =  this.$route.query.id
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>